<template>
    <div class="content d-flex">
        <div class="aside-right ">
            <!----FORGET PASSWORD------>
            <div class="card" v-if="card.resetCard">
                <div class="card-body">
                    <h4 class="card-title">Log In to C3 AI Ex Machina</h4>
                    <div class="fields">
                        <label>Username</label>
                        <InputText type="text" v-model="username" placeholder="Username"/>
                        <span v-if="error != ''">{{error.message}}</span>
                    </div>
                    <div class="login-btn mt-4">
                         <router-link to="/login"><h2 class="navigation-link">Back to<b>Sign in</b></h2></router-link> 
                        <Button label="SEND CODE" class="p-button-primary" @click="forgetPassword()"/>
                    </div>
                </div>
            </div>
            <!-----Confirm Forget Password ---->
            <div class="card" v-if="card.forgetPasswordCard">
                <div class="card-body">
                    <h4 class="card-title">Log In to C3 AI Ex Machina</h4>
                    <div class="fields">
                        <label>Confirmation Code<span class="text-danger">*</span></label>
                        <InputText type="text" v-model="code" placeholder="Ex: 321351"/>
                        <label class="mt-4" for="Password">New Password<span class="text-danger">*</span></label>
                        <InputText type="password" toggleMask v-model="password" placeholder="New password"/>
                        <span v-if="error != ''">{{error.message}}</span>
                    </div>
                    <div class="login-btn mt-4">
                        <h2 class="navigation-link">Dont received the code?<b @click="resendCode()">Resend</b></h2> 
                        <Button label="CONFIRM PASSWORD" class="p-button-primary" @click="confirmForgetPassword()"/>
                    </div>
                </div>
            </div>
            <!-- success card -->
            <div class="card" v-if="card.success">
                <div class="card-body">
                    <div class="box">
                        <i class="fas fa-check-circle text-success"></i>
                        <h1>Password changed</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
    name:"ForgetPassword",
    data(){
        return {
            username    : "",
            password    : "",
            email       : "",
            error       : "",
            code        : "",
            card: {
                forgetPasswordCard: false,
                resetCard          : true,
                success            :false,
            }
        }
    },
    methods: {
        ...mapActions({
            forgetPasswordVue           : "auth/forgetPassword",
            confirmForgetPasswordVue    : "auth/forgetPasswordSubmit",
            resendCodeVue               : "auth/resendCode"
        }),
        async forgetPassword() {
            try {
                await this.forgetPasswordVue({
                    username : this.username 
                })
                this.card.resetCard          = false;
                this.card.forgetPasswordCard = true;
                this.error = '';
            } catch (error){
                this.error = error;
            }
        },
        async confirmForgetPassword() {
            try {
                await this.confirmForgetPasswordVue({
                    username    : this.username,
                    code        : this.code,
                    password    : this.password
                })
                this.card.forgetPasswordCard = false;
                this.card.success = true;
                setTimeout(()=>{
                    this.$router.push('/login');
                },2000);
            } catch (error) {
                this.error = error;
            }
        },
        async resendCode (){
            try {
                await this.forgetPasswordVue({
                    username    : this.username,
                })
                alert("Check Email")
                this.error = "verification code is sent"
            } catch(error) {
                this.error = error;
            }

        }
    }
    
}
</script>

<style lang="scss" scoped>
@import "Auth.scss";
</style>